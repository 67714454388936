/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */

/* ---- Global Variables ---- */

@import "../../06-abstracts/breakpoint-mixins";

@import "../../06-abstracts/colors";

@import "../../06-abstracts/fonts";

@import "../../06-abstracts/utilities";

@import "../../06-abstracts/animation";


/* ------------------------------------------------------
**** MODULE CORE
------------------------------------------------------ */

@import "magnific-popup-core";


/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
    max-width: 1000px;
}

.mfp-ajax-holder .mfp-content {
    background-color: $color-white;
    margin: 0 auto;
    padding: 50px;
    max-width: 800px;
}

.mfp-inline-holder .mfp-content {
    background-color: $color-white;
    width: var(--default-container);
    margin: 0 auto;
    padding: 5vw;
}
